// src/components/experienceData.js
const experiences = [
    {
        title: "Senior Lead Technologist - Enterprise Data and AI Platforms",
        company: "Booz Allen Hamilton",
        date: "2020 - Present",
        description: "Lead a dynamic Agile technology team encompassing web app development, data engineering, data governance, data visualization, product management, systems engineering, cloud engineering, and technical project management.",
        tags: ["Tech Strategy", "Product Ownership", "IT Ops", "Leadership", "Azure", "AWS", "Databricks", "Data Engineering", "Software Engineering", "Business Intelligence", "ML/AI", "Data Governance"]
    },
    {
        title: "Lead Technologist - Enterprise Business Intelligence",
        company: "Booz Allen Hamilton",
        date: "2017 - 2020",
        description: "Managed key analytic platforms and products, empowering the business through data-driven insights.",
        tags: ["Tableau", "Alteryx", "Web Development", "Leadership"]
    },
    {
        title: "Global Real Estate Strategy and Analytics Lead",
        company: "JPMorgan Chase",
        date: "2013 - 2017",
        description: "Delivered analytics for Global Real Estate labor and location strategy.",
        tags: ["Analytics", "SQL", "Alteryx", "Tableau", "Strategy"]
    },
    {
        title: "Retail Branch Estate Project Manager",
        company: "JPMorgan Chase",
        date: "2010 - 2013",
        description: "Built and maintained core analytics for the retail real estate portfolio.",
        tags: ["Analytics", "Project Management", "SharePoint", "Reporting"]
    },
    {
        title: "Retail Branch Real Estate Analyst",
        company: "JPMorgan Chase",
        date: "2008 - 2010",
        description: "Managed analytics and reporting for the Chase rebranding project, ensuring seamless progress and financial target adherence. Provided executive reports for strategic planning and successful project outcomes.",
        tags: ["Analytics", "Reporting", "Project Management"]
    },
];

export default experiences;