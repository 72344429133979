import React, { useEffect } from 'react';
import { Container, CssBaseline, Box, Link, AppBar, Toolbar } from '@mui/material';
import About from './components/About';
import Projects from './components/Projects';
import Experience from './components/Experience';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#1e1e2e',
        },
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#f48fb1',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0b0b0',
        },
    },
});

const linkStyles = {
    margin: '0 10px',
    padding: '10px',
    textDecoration: 'none',
    color: 'inherit',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        transform: 'scaleX(0)',
        height: '2px',
        bottom: 0,
        left: 0,
        backgroundColor: '#90caf9',
        transformOrigin: 'bottom right',
        transition: 'transform 0.25s ease-out',
    },
    '&:hover::after': {
        transform: 'scaleX(1)',
        transformOrigin: 'bottom left',
    },
};

function App() {
    useEffect(() => {
        document.title = 'Andrew Stasi';
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar sx={{ justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 4 }}>
                        <Link href="#" sx={linkStyles}>
                            About
                        </Link>
                        <Link href="#experience" sx={linkStyles}>
                            Experience
                        </Link>
                        <Link href="#projects" sx={linkStyles}>
                            Projects
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar /> {/* This is to push the content below the AppBar */}
            <Container sx={{ backgroundColor: '#1e1e2e' }}>
                <Box id="about" sx={{ my: 4 }}>
                    <About />
                </Box>
                <Box id="experience" sx={{ my: 4 }}>
                    <Experience />
                </Box>
                <Box id="projects" sx={{ my: 4 }}>
                    <Projects />
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default App;