// src/components/Projects.js
import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Link } from '@mui/material';
import { motion } from 'framer-motion';

function Projects() {
    const projectList = [
        { 
            title: 'Beltway Buddy - DC Metro Info ChatGPT', 
            description: 'A ChatGPT application providing Washington DC metro information using OpenAI and a chat interface.', 
            link: 'https://chatgpt.com/g/g-P3F1yiV9f-beltway-buddy' 
        },
        {
            title: 'Personal Website',
            description: 'Built a personal website with React, mostly using GenAI to develop and deploy the code to Azure.',
            link: 'https://andrewstasi.com'
        }
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Projects
                </Typography>
                <Grid container spacing={4}>
                    {projectList.map((project, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{ backgroundColor: '#1e1e2e', boxShadow: 'none' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {project.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" paragraph>
                                        {project.description}
                                    </Typography>
                                    {project.link && (
                                        <Link href={project.link} underline="hover" color="primary" target="_blank" rel="noopener">
                                            {project.link}
                                        </Link>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </motion.div>
    );
}

export default Projects;