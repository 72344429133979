// src/components/Experience.js
import React from 'react';
import { Box, Typography, Card, CardContent, Grid, Chip } from '@mui/material';
import { motion } from 'framer-motion';
import experiences from './experienceData';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#1e1e2e', // Ensure this color matches the overall page background color
        },
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#f48fb1',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0b0b0',
        },
    },
});

const cardStyles = {
    backgroundColor: '#1e1e2e', // Matches the overall page background color
    boxShadow: 'none',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#2a2a3e', // Slightly different color to indicate hover
    },
};

const chipStyles = {
    borderColor: 'rgba(76, 175, 80, 0.7)', // Transparent green color for the tags
    backgroundColor: 'rgba(76, 175, 80, 0.3)', // More transparent background color for the tags
    color: '#ffffff', // Lighter text color for better readability
    fontWeight: 'bold', // Make the text bold
    transition: 'background-color 0.3s ease, border-color 0.3s ease',
    '&:hover': {
        backgroundColor: 'rgba(76, 175, 80, 0.5)', // Slightly darker transparent green on hover
        borderColor: 'rgba(76, 175, 80, 1)', // Less transparent border on hover
    },
};

function Experience() {
    return (
        <ThemeProvider theme={theme}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Experience
                    </Typography>
                    <Grid container spacing={4}>
                        {experiences.map((experience, index) => (
                            <Grid item xs={12} key={index}>
                                <Card sx={cardStyles}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            {experience.title} · {experience.company}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            {experience.date}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" paragraph>
                                            {experience.description}
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {experience.tags.map((tag, index) => (
                                                <Chip
                                                    key={index}
                                                    label={tag}
                                                    variant="outlined"
                                                    sx={chipStyles}
                                                />
                                            ))}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </motion.div>
        </ThemeProvider>
    );
}

export default Experience;