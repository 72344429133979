import React from 'react';
import { Box, Typography, IconButton, Link, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function About() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <Box sx={{ my: 4, mx: 2 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Andrew Stasi
                </Typography>
                <Typography variant="h6" component="p" gutterBottom>
                    Senior Technology Leader
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 2 }}>
                    <Link href="https://github.com/ARStasi" target="_blank" rel="noopener" sx={{ mx: 1 }}>
                        <IconButton>
                            <GitHubIcon sx={{ color: '#fff' }} />
                        </IconButton>
                    </Link>
                    <Link href="https://www.linkedin.com/in/andrew-stasi-b14960151/" target="_blank" rel="noopener" sx={{ mx: 1 }}>
                        <IconButton>
                            <LinkedInIcon sx={{ color: '#fff' }} />
                        </IconButton>
                    </Link>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body1" paragraph>
                    Hello, I’m Andrew! I thrive on tackling challenges, leading data-driven teams, and crafting innovative tech solutions. With a robust foundation in analytics within corporate real estate and extensive expertise across diverse technical domains, I blend deep industry knowledge with broad technical acumen to drive transformational outcomes.
                </Typography>
                <Typography variant="body1" paragraph>
                    Currently, I lead a technology-focused team at Booz Allen Hamilton within the central analytics function. Previously, I was with JPMorgan Chase in the Global Real Estate division, where I concentrated on labor and location strategy.
                </Typography>
                <Typography variant="body1" paragraph>
                    Every now and then, I enjoy tinkering with the latest tech. It helps me stay sharp and brings fresh insights to my role!
                </Typography>
            </Box>
        </motion.div>
    );
}

export default About;